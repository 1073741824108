/*
    Здесь мы видим функцию accordion, которая принимает параметр accordionElement (контейнер
    для элементов с классом collapse внутри этого контейнера, чтобы они работали как 
    части одного аккордиона). Весь связанный с ней функционал описан ВНУТРИ этой функции.
    Ниже мы экспортируем функцию launchAccordion, которая в свою очередь перебирает все
    элементы с классом accordion и запускает для каждого функцию accordion.
*/

const accordion = (accordionElement) => {
    const sections = accordionElement.querySelectorAll('.sat-collapse');
    sections.forEach((section) => {
        // Подписываемся на открытие определённой части аккордиона
        section.addEventListener('sat.collapse.open', () => {
            // Закрываем все остальные части аккордеона
            sections.forEach((s) => {
                // Конструкция if нужна для того, чтобы не закрылась только что открытая часть аккордеона
                if (s !== section) s.controller.close();
            });
        });
    });
};

export const launchAccordion = () => {
    document.querySelectorAll('.sat-accordion').forEach(accordion);
};