// const Tree0 = root0 => {
//     const id = root0.id;

//     if (!root0) throw new Error('Please provide an ID attribute for tree');

//     const LOCALSTORAGE_KEY = 'SINAM_TREE_' + id;
//     const allNodes = root0.querySelectorAll('.tree0__node');
//     const nodesWithSubtree = [...allNodes].filter((node) => node.querySelector('.tree0__subtree'));

//     let openedNodes = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || [];

//     const openNode = (node, nodeId) => {
//         node.classList.add('tree0__node_open');
//         if (!openedNodes.includes(nodeId)) openedNodes.push(nodeId);
//     };

//     const closeNode = (node, nodeId) => {
//         node.classList.remove('tree0__node_open');
//         openedNodes = openedNodes.filter((id) => id !== nodeId);
//     };

//     const toggleNode = (node, nodeId) => {
//         const opened = node.classList.contains('tree0__node_open');
//         opened ? closeNode(node, nodeId) : openNode(node, nodeId);
//     };

//     nodesWithSubtree.forEach((node, index) => {
//         if (openedNodes.includes(index)) openNode(node, index)
//         node.querySelector('.tree0__collapse').addEventListener('click', () => {
//             toggleNode(node, index);
//         });
//     });

//     window.addEventListener('beforeunload', () => {
//         localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(openedNodes));
//     });

// };

// export const launchTree0 = () => {
//     document.querySelectorAll('.tree0').forEach(Tree0);
// };

//заменили на class
class Tree0 {
    constructor(root0) {
        const id = root0.id;

        if (!root0) throw new Error('Please provide an ID attribute for tree');

        //const заменили на this
        this.LOCALSTORAGE_KEY = 'SAT_TREE_' + id;

        const allNodes = root0.querySelectorAll('.tree0__node');

        const nodesWithSubtree = [...allNodes].filter((node) => node.querySelector('.tree0__subtree'));

        this.openedNodes = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_KEY)) || [];

        const openNode = (node, nodeId) => {
            node.classList.add('tree0__node_open');
            if (!this.openedNodes.includes(nodeId)) this.openedNodes.push(nodeId);
        };

        const closeNode = (node, nodeId) => {
            node.classList.remove('tree0__node_open');
            this.openedNodes = this.openedNodes.filter((id) => id !== nodeId);
        };

        const toggleNode = (node, nodeId) => {
            const opened = node.classList.contains('tree0__node_open');
            opened ? closeNode(node, nodeId) : openNode(node, nodeId);
        };

        nodesWithSubtree.forEach((node, index) => {
            if (this.openedNodes.includes(index)) openNode(node, index)
            node.querySelector('.tree0__collapse').addEventListener('click', () => {
                toggleNode(node, index);
            });
        });

        window.addEventListener('beforeunload', () => {
            localStorage.setItem(this.LOCALSTORAGE_KEY, JSON.stringify(this.openedNodes));
        });
    }

    resetTree() {
        this.openedNodes = [];
        //localStorage.removeItem(this.LOCALSTORAGE_KEY);
    }
};

export const launchTree0 = () => {
    document.querySelectorAll('.tree0').forEach((element) => {
        //element.setAttribute('data-tree', new Tree0(element));//сохраняем ссылку на экземпляр в дата атрибуте
        element['data-tree'] = new Tree0(element);
    });
};

export const clearLocalStorage = () => {
    document.querySelectorAll('.tree0').forEach((element) => {
        let tree = element['data-tree'];//читаем дата атрибут, там ссылка на экземпляр Tree0
        tree.resetTree();
    });
}

