import { HTML2DOM } from "../lib/DOM";

export const createRootNode = () => {
  const element = HTML2DOM('<div class="tree tree-branch"></div>');
  const setReady = (ready) => element.classList.toggle("tree_ready", ready);

  return {
    type: "root",
    DOM: { element, childrenContainer: element },
    setReady
  };
};
