"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  days: ['Bazar', 'Bazar ertəs', 'Çərşənbə axşamı', 'Çərşənbə', 'Cümə axşamı', 'Cümə', 'Şənbə'],
  daysShort: ['B.', 'B. e.', 'Ç.a.', 'Ç.', 'C.a.', 'C.', 'Ş.'],
  daysMin: ['B.', 'B. e.', 'Ç.a.', 'Ç.', 'C.a.', 'C.', 'Ş.'],
  months: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'İyun', 'İyul', 'Avqust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr'],
  monthsShort: ['Yan', 'Fev', 'Mar', 'Apr', 'May', 'İyun', 'İyul', 'Avg', 'Sen', 'Okt', 'Noy', 'Dek'],
  today: 'Bu gün',
  clear: 'Silmək',
  dateFormat: 'dd.MM.yyyy',
  timeFormat: 'HH:mm',
  firstDay: 1
};
exports.default = _default;