import AirDatepicker from '../../self-hosted-external-npms/air-datepicker/air-datepicker';
import localeAz from './locale/az';

export const launchDatePicker = (id) => {

    let elements = document.querySelectorAll('[data-airDatePicker="1"]');

    if (id == null) {
        elements = document.querySelectorAll('[data-airDatePicker="1"]');
    }
    else {
        elements = document.querySelectorAll('#' + id);
    }

    function position({ $datepicker, $target, $pointer }) {
        //console.log('position');
        let coords = $target.getBoundingClientRect(),
            dpHeight = $datepicker.clientHeight,
            dpWidth = $datepicker.clientWidth;

        let top = coords.y + coords.height + window.scrollY;
        let left = coords.x + window.scrollX;

        // console.log('dpHeight = ' + dpHeight)
        // console.log('dpWidth = ' + dpWidth)
        // console.log($target);

        if ((coords.y + dpHeight) > window.innerHeight) {
            top = coords.y - dpHeight + window.scrollY;
        }

        if ((coords.x + dpWidth) > window.innerWidth) {
            left = coords.x + coords.width - dpWidth + window.scrollX;
        }

        $datepicker.style.left = `${left}px`;
        $datepicker.style.top = `${top}px`;

        $pointer.style.display = 'none';
    }

    for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let type = element.getAttribute('data-airDatePickerType');
        switch (type) {
            case 'normal':
                new AirDatepicker(element, {
                    autoClose: true,
                    locale: localeAz,
                    position: position
                });
                break;
            case 'daterange':
                new AirDatepicker(element, {
                    autoClose: true,
                    locale: localeAz,
                    range: true,
                    multipleDatesSeparator: ' - ',
                    position: position
                });
                break;
            case 'datetimepicker':
                new AirDatepicker(element, {
                    autoClose: true,
                    locale: localeAz,
                    timepicker: true,
                    position: position
                });
                break;
            case 'atmodal':
                new AirDatepicker(element, {
                    locale: localeAz,
                    autoClose: true,
                    isMobile: true,
                    autoClose: true,
                    position: position
                });
                break;
            case 'datetimepickerAz':
                new AirDatepicker(element, {
                    locale: localeAz,
                    autoClose: true,
                    timepicker: true,
                    position: position
                });
                break;
        }
    }
}