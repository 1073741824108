export const createEvent = () => {
  const listeners = new Set();

  const event = (...payload) =>
    listeners.forEach((listener) => listener(...payload));

  event.listen = (handler) => listeners.add(handler);

  event.off = (handler) => listeners.delete(handler);

  return event;
};
