const freezeColumns = table => {
    const columnsCountDefault = table.dataset.freezeCount || 0;

    const rows = table.querySelectorAll('tr');
    const freeze = () => {
        rows.forEach(row => {
            const columnsCount = row.dataset.freezeCount || columnsCountDefault;
            const coll1 = row.querySelectorAll(`td:nth-child(-n+${columnsCount}),th:nth-child(-n+${columnsCount})`);
            const coll2 = row.querySelectorAll('td[data-freeze-cell],th[data-freeze-cell]');
            const tdsToFreeze = [];
            coll1.forEach(cell => tdsToFreeze.push(cell));
            coll2.forEach(cell => tdsToFreeze.push(cell));

            tdsToFreeze.forEach(td => {
                td.style.left = '';
                td.classList.remove('sticky');
            });

            tdsToFreeze.forEach(td => {
                td.style.left = td.offsetLeft + 'px'
                td.classList.add('sticky')
            });


        });
    };

    new ResizeObserver(freeze).observe(table);
}

export const launchFreezeSomeTableColumns = () => {
    document.querySelectorAll('.freezed-columns').forEach(freezeColumns)
}
