/*
    Здесь мы экспортируем функцию launchActiveMenu,
    которая делает ссылку в дереве для текущей страницы активной.
*/

export const launchActiveMenu = () => {

    // Для проекта SMB (v1)
    let currentPageUrl = ".." + window.location.pathname;
    // Для других проектов (v2)
    //let currentPageUrl = window.location.pathname.substring(window.location.pathname.indexOf('/'));        

    // Ищем все ссылки с классом tree0__link
    const links = document.getElementsByClassName('tree0__link');

    // Если ссылка ведёт на текущую страницу, то добавляем ей класс tree0__active
    for (let i = 0; i < links.length; i++) {
        if (links[i].getAttribute('href') == currentPageUrl) {
            links[i].classList.add('tree0__active');
        } else {
            links[i].classList.remove('tree0__active');
        }
    }
};