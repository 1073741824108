export const offCanvasMenu = () => {

    const hamburger = document.querySelector('.hamburger');

    const aside = document.querySelector('.layout__left');



    // new 9-11
    //todo: перенести на класс
    //const mapPanel = document.querySelector('.typical-table-layout__left_wide');



    let isMenuOpened = true;

    if (localStorage.getItem('SAT_OFFCANVAS_MENU') === null) {
        console.log('no localstorage record');
        localStorage.setItem('SAT_OFFCANVAS_MENU', 'yes');
    };

    const open = () => {
        isMenuOpened = true;
        aside.classList.remove('layout__left_hidden');
        requestAnimationFrame(() => {
            aside.dispatchEvent(new Event('sidemenu.open'));



            // new 30-33
            //todo: перенести на класс
            //if(mapPanel) mapPanel.style.display = 'block';
            // new
        });
        localStorage.setItem('SAT_OFFCANVAS_MENU', 'yes');
    };

    const close = () => {
        isMenuOpened = false;
        aside.classList.add('layout__left_hidden');
        requestAnimationFrame(() => {
            aside.dispatchEvent(new Event('sidemenu.close'));

            

            // new 46-49
            //todo: перенести на класс
            //if(mapPanel) mapPanel.style.display = 'none';
            // new
        });
        localStorage.setItem('SAT_OFFCANVAS_MENU', 'no');
    };

    if (hamburger) {
        hamburger.addEventListener('click', () => {
            if (isMenuOpened) {
                close();
            } else {
                open();
            };
        });
    }

    if (localStorage.getItem('SAT_OFFCANVAS_MENU') === 'no') {
        close();
    };

};