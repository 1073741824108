import { classNames, HTML2DOM } from "../lib/DOM";
import { renderBadges } from "./render-badge";

export const createLeafNode = (
  leafNodeDeclaration,
  parentNode,
  {defaultLeafIcon, depth}
) => {
  const { link, label, icon, badges = []} = leafNodeDeclaration;

  const finalIcon = icon || defaultLeafIcon;

  const cn = classNames(
    "tree-branch__leaf",
    finalIcon && "tree-branch__leaf_has-icon"
  );

  const element = HTML2DOM(`
    <div class="${cn}" style="--depth:${depth}">
      ${
        finalIcon
          ? `<img class="tree-branch__icon" src="${finalIcon}" alt="${label}">`
          : ""
      }
      <a class="tree-branch__link" href="${link}">${label}</a>
      ${renderBadges(badges)}
    </div>
  `);

  const setActive = (active) =>
    element.classList.toggle("tree-branch__leaf_active", active);

  return {
    type: "leaf",
    DOM: { element, childrenContainer: element },
    parent: parentNode,
    link,
    label,
    setActive
  };
};
