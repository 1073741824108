import TomSelect from 'tom-select';

export const launchTomSelect = (id) => {

    function autocompleteOnPost() {
        let self = this;
        let value;
        let xhr = null;



        //new
        let valueKey = this.input.getAttribute('data-valueKey') || "id"; // default
        let textKey = this.input.getAttribute('data-textKey') || "name"; // default

        function reqListener() {
            let options = JSON.parse(this.responseText).d;
            self.clear();
            self.clearOptions();

            options.forEach(option => {
                self.addOption({
                    value: option[valueKey],
                    text: option[textKey]
                });
            });

            self.refreshOptions(true);
        }

        function send() {

            if (xhr != null) {
                xhr.abort();
            }

            xhr = new XMLHttpRequest();
            let srcUrl = self.input.getAttribute('data-srcurl');
            let index = srcUrl.indexOf('?');

            let webMethodAddres = srcUrl.substr(0, index);

            let params = {};
            
            let queryString = srcUrl.substr(index + 1);
            let parts = queryString.split('&');

            for (let i = 0; i < parts.length; i++) {
                let [name, value] = parts[i].split('=');
                // let decodedName = decodeURIComponent(parts[0]);
                // let decodedValue = decodeURIComponent(parts[1]);
                let decodedName = decodeURIComponent(name);
                let decodedValue = decodeURIComponent(value);
                params[decodedName] = decodedValue
            }

            params.searchText = value;

            let data = JSON.stringify(params);

            xhr.open('POST', webMethodAddres, true);

            xhr.addEventListener("load", reqListener);
            xhr.setRequestHeader("content-type", "application/json");
            xhr.send(data);


        }

        let timer = null;

        let srcUrl = self.input.getAttribute('data-srcurl');
        if (srcUrl != '' && srcUrl != null) {
            this.on('type', function (a) {
                value = a;
                if (timer != null) {
                    clearTimeout(timer);
                }
                if (xhr != null) {
                    xhr.abort();
                }
                timer = setTimeout(send, 200);
            });
        }

        this.on('destroy', function () {
            self.off('type');
        });
    }
    TomSelect.define('autocompleteOnPost', autocompleteOnPost);


    function dropup() {
        let self = this;
        this.on('initialize', function () {
            self.on('dropdown_open', function (dropdown) {
                var rect = dropdown.getBoundingClientRect();
                if (rect.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
                    dropdown.style.position = 'absolute';
                    console.log(rect.top, rect.height);
                    dropdown.style.top = -rect.height - 7 + 'px';
                }
            });
            self.on('dropdown_close', function (dropdown) {
                dropdown.style.position = '';
                dropdown.style.top = '';
            });
        });
        this.on('destroy', function () {
            self.off('dropdown_open');
            self.off('dropdown_close');
        });
    }
    TomSelect.define('dropup', dropup);

    let elements;

    if (id == null) {
        elements = document.querySelectorAll('[data-tomSelect="1"]');
    } else {
        elements = document.querySelectorAll('#' + id);
    }

    for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let type = element.getAttribute('data-tomSelectType');
        switch (type) {
            case 'input':
                new TomSelect(element, {
                    persist: false,
                    createOnBlur: true,
                    create: true,
                    plugins: ['dropup', 'autocompleteOnPost']
                });
                break;
            case 'selectState':
                new TomSelect(element, {
                    maxItems: 3,
                    plugins: ['clear_button', 'dropup', 'autocompleteOnPost']
                });
                break;
            case 'selectBeast':
                new TomSelect(element, {
                    create: true,
                    plugins: ['clear_button', 'dropup', 'autocompleteOnPost']
                });
                break;
            case 'selectSingleWithoutAddCustomValue':
                new TomSelect(element, {
                    create: false,
                    plugins: ['clear_button', 'dropup', 'autocompleteOnPost']
                });
                break;
            case 'disabledSelectType':
                new TomSelect("#select-beast-disabled");
                break;
            case 'selectMultipleWithoutAddCustomValue':
                new TomSelect(element, {
                    create: false,
                    plugins: ['clear_button', 'dropup', 'autocompleteOnPost']
                });
                break;
            case 'checkBox':
                new TomSelect(element, {
                    plugins: ['checkbox_options', 'dropup', 'autocompleteOnPost'],
                });
                break;
        }
    };
}