const createDrop = element1 => {

    let opened = false;

    const hitarea = element1.querySelector('.drop__hitarea');

    const content = element1.querySelector('.drop__content');

    const open = () => {
        opened = true;
        hitarea.classList.toggle('drop__hitarea_active', opened);
        content.classList.toggle('drop__content_active', opened);
    };

    const close = () => {
        opened = false;
        hitarea.classList.toggle('drop__hitarea_active', opened);
        content.classList.toggle('drop__content_active', opened);
    };

    const toggle = () => {
        if (opened) close();
        else open();
    };

    hitarea.addEventListener('click', toggle);

    document.addEventListener('click', e => {
        if (!e.target.closest('.drop')) close();
    });
};

export const launchDrop = () => {
    document.querySelectorAll('.drop').forEach(createDrop);
};