/*
    INDEX.JS - файл, с которого Webpack считывает все .js/.scss файлы,
    которые необходимо включать в процессе сборки.
*/

/*
    FEATURES/INDEX.JS - файл, в котором собираются ВСЕ .JS файлы относящиеся к конкретным фичам.
*/
import './features/index';

/*
    INDEX.SCSS - файл, в котором собираются ВСЕ .SCSS файлы.
*/
import './index.scss';