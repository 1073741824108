import { createEvent } from "../lib/events";

import { createLeafNode } from "./../nodes/leaf";
import { createBranchNode } from "./../nodes/branch";
import { createRootNode } from "./../nodes/root";
import { deepMap } from "../lib/tree-structure";

export const createNodes = (treeDeclaration) => {
  const branches = [];
  const leaves = [];

  const { nodes, defaultBranchIcon, defaultLeafIcon } = treeDeclaration;

  const branchToggleRequested = createEvent();
  const leafActiveToggleRequested = createEvent();

  const rootNode = createRootNode();

  const compiledNodes = deepMap(
    nodes,
    (node, depth, parentNode) => {
      const isBranch = !!node.children;

      if (isBranch) {
        const newNode = createBranchNode(node, parentNode,
          { 
            depth, 
            onToggleRequest: () => branchToggleRequested(newNode), 
            defaultBranchIcon, 
          }
        );
        parentNode.DOM.childrenContainer.append(newNode.DOM.element);
        branches.push(newNode);
        return newNode;
      }

      const newNode = createLeafNode(node, parentNode, { defaultLeafIcon, depth});
      parentNode.DOM.childrenContainer.append(newNode.DOM.element);
      leaves.push(newNode);
      return newNode;
    },
    rootNode
  );

  return {
    rootNode,
    nodes: compiledNodes,
    branches,
    leaves,
    events: {
      branchToggleRequested,
      leafActiveToggleRequested
    }
  };
};
