export const deepForEach = (nodes, fn, parentNode, depth = 0) => {
  return nodes.forEach((child) => {
    const newChild = fn(child, depth, parentNode);
    if (child.children) deepForEach(child.children, fn, newChild, depth + 1);
  });
};

export const deepMap = (nodes, fn, parentNode, depth = 0) => {
  return nodes.map((child) => {
    const newChild = fn(child, depth, parentNode);
    if (child.children)
      newChild.children = deepMap(child.children, fn, newChild, depth + 1);
    return newChild;
  });
};

export const forEachParent = ({ parent }, fn) => {
  if (parent) {
    fn(parent);
    forEachParent(parent, fn);
  }
};
