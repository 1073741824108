import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';

import { launchTomSelect } from "./tom-select/tom-select";
import { launchDatePicker } from './datepicker/datepicker';
import { launchTooltip } from './tooltip/tooltip';
import { launchTree0, clearLocalStorage } from './tree/tree0';

import { launchTreeNova } from './treenova';
//import { launchtreenova, clearLocalStorageNova } from './treenova/treenova';
import { launchFilterOnFly } from './filteronfly/filteronfly';

import { launchNotificationsList } from './notifications-list/notifications-list';
import { launchDrop } from './drop/drop';
import { launchAccordion } from './accordion/accordion';
import { launchCollapse } from './collapse/collapse';
import { offCanvasMenu } from './menu/offCanvasMenu';
import { typicalTableLayout } from './typical-table-layout/typical-table-layout';
import { launchSatModals } from './modal/modal';
import { launchActiveMenu } from './activemenu/activemenu';
import { launchAmCharts } from './amcharts/amcharts';
import { launchFreezeSomeTableColumns } from './table/table';
import { launchLightBox } from './lightbox/lightbox';
//import { logoutCleanUp } from './logoutcleanup/logoutcleanup';
import { launchMask } from './mask/mask';


window.launchAllFunctions = () => {
    launchSatModals();
    launchTree0();
    
    launchTreeNova();
    //launchtreenova();
    launchFilterOnFly();

    launchNotificationsList();
    launchDrop();
    launchAccordion();
    launchCollapse();
    launchTomSelect();
    launchDatePicker();
    launchTooltip();
    offCanvasMenu();
    typicalTableLayout();
    launchActiveMenu();
    launchAmCharts();
    launchFreezeSomeTableColumns();
    launchLightBox();
    launchMask();
};

window.launchAllFunctions();

window.launchAllFunctions.launchLightBox = launchLightBox;
window.launchAllFunctions.launchTomSelect = launchTomSelect;
window.launchAllFunctions.launchDatePicker = launchDatePicker;
//window.clearLocalStorage = clearLocalStorage;
//window.clearLocalStorageNova = clearLocalStorageNova;

//window.logoutCleanUp = logoutCleanUp;

// onload = function () {
//     let tds = document.querySelectorAll('table.exampleclassfreeze tr td:nth-child(-n+3)')
//     for (let i = 0; i < tds.length; i++) {
//         let left = tds[i].offsetLeft + 'px';
//         tds[i].style.left = left;
//         tds[i].className = 'sticky';
//     }
// }