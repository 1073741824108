import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export const launchAmCharts = () => {
    let elements = document.querySelectorAll('[data-amChart="1"]');
    for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let type = element.getAttribute('data-amChartType');
        switch (type) {
            case 'columnWithRotatedLabels':
                am5.ready(function () {
                    var root = am5.Root.new(element);
                    root.setThemes([
                        am5themes_Animated.new(root)
                    ]);
                    var chart = root.container.children.push(am5xy.XYChart.new(root, {
                        panX: true,
                        panY: true,
                        wheelX: "panX",
                        wheelY: "zoomX",
                        pinchZoomX: true
                    }));
                    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                    cursor.lineY.set("visible", false);
                    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
                    xRenderer.labels.template.setAll({
                        rotation: -90,
                        centerY: am5.p50,
                        centerX: am5.p100,
                        paddingRight: 15
                    });
                    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                        maxDeviation: 0.3,
                        categoryField: "country",
                        renderer: xRenderer,
                        tooltip: am5.Tooltip.new(root, {})
                    }));
                    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                        maxDeviation: 0.3,
                        renderer: am5xy.AxisRendererY.new(root, {})
                    }));
                    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                        name: "Series 1",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        sequencedInterpolation: true,
                        categoryXField: "country",
                        tooltip: am5.Tooltip.new(root, {
                            labelText: "{valueY}"
                        })
                    }));
                    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
                    series.columns.template.adapters.add("fill", function (fill, target) {
                        return chart.get("colors").getIndex(series.columns.indexOf(target));
                    });
                    series.columns.template.adapters.add("stroke", function (stroke, target) {
                        return chart.get("colors").getIndex(series.columns.indexOf(target));
                    });
                    var data = [{
                        country: "USA",
                        value: 2025
                    }, {
                        country: "China",
                        value: 1882
                    }, {
                        country: "Japan",
                        value: 1809
                    }, {
                        country: "Germany",
                        value: 1322
                    }, {
                        country: "UK",
                        value: 1122
                    }, {
                        country: "France",
                        value: 1114
                    }, {
                        country: "India",
                        value: 984
                    }, {
                        country: "Spain",
                        value: 711
                    }, {
                        country: "Netherlands",
                        value: 665
                    }, {
                        country: "Russia",
                        value: 580
                    }, {
                        country: "South Korea",
                        value: 443
                    }, {
                        country: "Canada",
                        value: 441
                    }];
                    xAxis.data.setAll(data);
                    series.data.setAll(data);
                    series.appear(1000);
                    chart.appear(1000, 100);
                });
                break;
            case 'simpleColumnChart':
                am5.ready(function () {
                    var root = am5.Root.new(element);
                    root.setThemes([
                        am5themes_Animated.new(root)
                    ]);
                    var chart = root.container.children.push(am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "panX",
                        wheelY: "zoomX"
                    }));
                    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                        behavior: "zoomX"
                    }));
                    cursor.lineY.set("visible", false);
                    var date = new Date();
                    date.setHours(0, 0, 0, 0);
                    var value = 100;
                    function generateData() {
                        value = Math.round((Math.random() * 10 - 5) + value);
                        am5.time.add(date, "day", 1);
                        return {
                            date: date.getTime(),
                            value: value
                        };
                    }
                    function generateDatas(count) {
                        var data = [];
                        for (var i = 0; i < count; ++i) {
                            data.push(generateData());
                        }
                        return data;
                    }
                    var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                        maxDeviation: 0,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1
                        },
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        tooltip: am5.Tooltip.new(root, {})
                    }));
                    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {})
                    }));
                    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                        name: "Series",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        valueXField: "date",
                        tooltip: am5.Tooltip.new(root, {
                            labelText: "{valueY}"
                        })
                    }));
                    chart.set("scrollbarX", am5.Scrollbar.new(root, {
                        orientation: "horizontal"
                    }));
                    var data = generateDatas(50);
                    series.data.setAll(data);
                    series.appear(1000);
                    chart.appear(1000, 100);
                });
                break;
            // New
            case '100StackedColumnChart':
                am5.ready(function () {
                    var root = am5.Root.new(element);
                    root.setThemes([
                        am5themes_Animated.new(root)
                    ]);
                    var chart = root.container.children.push(am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "panX",
                        wheelY: "zoomX",
                        layout: root.verticalLayout
                    }));
                    chart.set("scrollbarX", am5.Scrollbar.new(root, {
                        orientation: "horizontal"
                    }));
                    var data = [{
                        "year": "2021",
                        "europe": 2.5,
                        "namerica": 2.5,
                        "asia": 2.1,
                        "lamerica": 1,
                        "meast": 0.8,
                        "africa": 0.4
                    }, {
                        "year": "2022",
                        "europe": 2.6,
                        "namerica": 2.7,
                        "asia": 2.2,
                        "lamerica": 0.5,
                        "meast": 0.4,
                        "africa": 0.3
                    }, {
                        "year": "2023",
                        "europe": 2.8,
                        "namerica": 2.9,
                        "asia": 2.4,
                        "lamerica": 0.3,
                        "meast": 0.9,
                        "africa": 0.5
                    }]
                    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                        categoryField: "year",
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        tooltip: am5.Tooltip.new(root, {})
                    }));
                    xAxis.data.setAll(data);
                    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                        min: 0,
                        max: 100,
                        numberFormat: "#'%'",
                        strictMinMax: true,
                        calculateTotals: true,
                        renderer: am5xy.AxisRendererY.new(root, {})
                    }));
                    var legend = chart.children.push(am5.Legend.new(root, {
                        centerX: am5.p50,
                        x: am5.p50
                    }));
                    function makeSeries(name, fieldName) {
                        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                            name: name,
                            stacked: true,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: fieldName,
                            valueYShow: "valueYTotalPercent",
                            categoryXField: "year"
                        }));
                        series.columns.template.setAll({
                            tooltipText: "{name}, {categoryX}:{valueYTotalPercent.formatNumber('#.#')}%",
                            tooltipY: am5.percent(10)
                        });
                        series.data.setAll(data);
                        series.appear();
                        series.bullets.push(function () {
                            return am5.Bullet.new(root, {
                                sprite: am5.Label.new(root, {
                                    text: "{valueYTotalPercent.formatNumber('#.#')}%",
                                    fill: root.interfaceColors.get("alternativeText"),
                                    centerY: am5.p50,
                                    centerX: am5.p50,
                                    populateText: true
                                })
                            });
                        });
                        legend.data.push(series);
                    }
                    makeSeries("Europe", "europe");
                    makeSeries("North America", "namerica");
                    makeSeries("Asia", "asia");
                    makeSeries("Latin America", "lamerica");
                    makeSeries("Middle East", "meast");
                    makeSeries("Africa", "africa");
                    chart.appear(1000, 100);
                });
                break;
        }
    }
}