const createNotificationsList = element0 => {

    let notifications = [...element0.querySelectorAll('.notification')];

    const empty = element0.querySelector('.notifications-list__empty');

    const showEmpty = () => {
        //console.log('show epmty');
        empty.classList.add('notifications-list__empty_active');
    };

    const hideEmpty = () => empty.classList.remove('notifications-list__empty_active');

    const updateEmpty = () => {
        //console.log('update', notifications.length);
        if (notifications.length === 0) showEmpty();
        else hideEmpty();
    };

    const removeNotification = (element0) => {
        element0.remove();
        notifications = notifications.filter(not => not !== element0);
        updateEmpty();
    };

    notifications.forEach(notification => {
        const remove = notification.querySelector('.notification__close');
        remove.addEventListener('click', (e) => {
            e.stopPropagation();
            removeNotification(notification);
        });
    });

    updateEmpty();
};

export const launchNotificationsList = () => {
    document.querySelectorAll('.notifications-list').forEach(createNotificationsList);
};