import IMask from "imask";

function blurHandler(event) {
    let element = event.target;
    let imask = element.imask;
    if (!imask.masked.isComplete) {
        element.value = '';
        imask.masked.unmaskedValue = '';
    }
}

function focusHandler(event) {
    event.target.imask.updateControl();
}

export const launchMask = () => {
    let elements = document.getElementsByClassName('dateMask');

    for (let element of elements) {
        let dateMask = IMask(
            element, {
                mask: Date,
                min: new Date(1900, 0, 1),
                max: new Date(2050, 0, 1),
                lazy: false
            });

        element.imask = dateMask;

        element.addEventListener('blur', blurHandler);
        element.addEventListener('focus', focusHandler);

        // element.value = '';
        if (!element.imask.masked.isComplete) {
            element.value = '';
        }
    }

    window.addEventListener('onbeforeunload', function () {
        for (let i = 0; i < elements.length; i++) {
            let element = elements[i];
            element.removeEventListener('blur', blurHandler);
            element.removeEventListener('focus', focusHandler);
            element.imask.destroy();
            delete element.imask;
        }
    });
}