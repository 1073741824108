import { browsingSession } from "./lib/browsing-session";
import { forEachParent } from "./lib/tree-structure";
import { createNodes } from "./nodes";
import { validateConfig } from "./lib/validate-config.js";
import "./styles.scss";
import { absolutify } from "./lib/url";




const createTreeState = (id = null) => {
  let opened = new Set();
  let closed = new Set();

  /** State of the tree can be overrided in some scenarios, however, such changes will not be persisted*/
  const temporarilyOpened = new Set();

  if (id) {
    const openedKey = id + "_opened";
    const closedKey = id + "_closed";

    opened = new Set(JSON.parse(localStorage.getItem(openedKey)) || []);
    closed = new Set(JSON.parse(localStorage.getItem(closedKey)) || []);
  
    window.addEventListener("beforeunload", () => {
      localStorage.setItem(openedKey, JSON.stringify(Array.from(opened)));
      localStorage.setItem(closedKey, JSON.stringify(Array.from(closed)));
    });
  }

  return {
    opened,
    closed,
    temporarilyOpened
  };
};

const createTree = (scriptElement) => {
  const config = JSON.parse(scriptElement.textContent);
  validateConfig(config);

  const { opened, closed, temporarilyOpened } = createTreeState(config.id);

  /** There are scenarios that require to know if page was opened via direct link or via page relaod */
  const pageIsLoadedDirectly = !browsingSession.isReloaded;

  const { branches, rootNode, leaves, events } = createNodes(config);
  const { branchToggleRequested } = events;

  let activeLeaf = leaves.find((leaf) => absolutify(leaf.link) === window.location.href);

  /** Opens branch "as user" meaning
   * changed branch state will be persisted
   * within session
   */
  const openBranchAsUser = (branch) => {
    branch.setOpened(true);
    closed.delete(branch.id);
    opened.add(branch.id);
  };

  const openBranchTemporarily = (branch) => {
    branch.setOpened(true);
    temporarilyOpened.add(branch.id);
  };

  /** Closes branch "as user" */
  const closeBranchAsUser = (branch) => {
    branch.setOpened(false);
    closed.add(branch.id);
    opened.delete(branch.id);
    temporarilyOpened.delete(branch.id);
  };

  /** Toggles branch "as user" */
  const toggleBranchAsUser = (branch) => {
    const { id } = branch;

    if (opened.has(id) || temporarilyOpened.has(id)) {
      closeBranchAsUser(branch);
    } else openBranchAsUser(branch);
  };

  const setLeafActive = (leaf) => {
    leaf.setActive(true);
    forEachParent(leaf, (parent) => {
      if (parent.type === "branch") {
        parent.setHasActive(true);
        parent.setHasActive(true);
        if (pageIsLoadedDirectly) openBranchTemporarily(parent);
      }
    });
  };

  if (activeLeaf) setLeafActive(activeLeaf);

  branchToggleRequested.listen(toggleBranchAsUser);

  branches.forEach((branch) => {
    if (opened.has(branch.id)) openBranchAsUser(branch);
  });

  scriptElement.parentNode.replaceChild(rootNode.DOM.element, scriptElement);

  rootNode.setReady(true);
};

export const launchTreeNova = () => {
  document.querySelectorAll("script.tree").forEach(createTree);
}