import GLightbox from 'glightbox';

export const launchLightBox = (id) => {

    //let elements = document.querySelectorAll('[data-glightboxStart="1"]');

    let elements;

    if (id == null) {
        elements = document.querySelectorAll('[data-glightboxStart="1"]');
    }
    else {
        elements = document.querySelectorAll('#' + id);
    }

    for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let type = element.getAttribute('data-glightboxType');
        switch (type) {
            case 'simpleImage':
                GLightbox({
                    touchNavigation: true,
                    loop: true,
                    autoplayVideos: true
                });
                break;
        }
    };

}