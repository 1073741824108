/*
    Функция для скрытия/показа элементов на странице.
    Принимает параметр collapseElement, который
    является контейнером для контента, который нужно скрывать/показывать.

    <div>
        <div class="collapse__hitarea">
            Место для клика
        </div>
        <div class="collapse__content">
            Контент
        </div>
    </div>

*/

const collapseSAT = (collapseElement) => {
    if (collapseElement.controller) return collapseElement.controller;
    const content = collapseElement.querySelector('.sat-collapse__content');
    const hitarea = collapseElement.querySelector('.sat-collapse-hitarea');
    let isOpen = collapseElement.classList.contains('.sat-collapse_open');
    if (!hitarea || !content) throw new Error('No hitarea or no content element found');

    // Функция для показывания элемента.
    const open = () => {
        collapseElement.classList.add('sat-collapse_open');
        hitarea.classList.add('sat-collapse-hitarea_active');
        content.style.height = content.scrollHeight + 'px';

        // Выкидывание события sat.collapse.open, если элемент ОТКРЫЛСЯ.
        if (!isOpen) dispatchOpenEvent();
        isOpen = true;
    };

    // Функция для скрытия элемента.
    const close = () => {
        collapseElement.classList.remove('sat-collapse_open');
        hitarea.classList.remove('sat-collapse-hitarea_active');
        content.style.height = null;

        // Выкидывание события sat.collapse.close, если элемент ЗАКРЫЛСЯ.
        if (isOpen) dispatchCloseEvent();
        isOpen = false;
    }

    // Функция для показывания/скрытия (в зависимости от текущего состояния элемента) элемента.
    const toggle = () => {
        if (isOpen) close();
        else open();
    }

    // Создание и отправка нового события открытия контента.
    const dispatchOpenEvent = () => collapseElement.dispatchEvent(new Event('sat.collapse.open'));

    // Создание и отправка нового события закрытия контента.
    const dispatchCloseEvent = () => collapseElement.dispatchEvent(new Event('sat.collapse.close'));

    // Вешаем функцию toggle на обработчик клика.
    hitarea.addEventListener('click', toggle);

    // 
    const instance = {
        open,
        close,
        toggle
    };

    collapseElement.controller = instance;

    return instance;
}

/*
    Здесь мы экспортируем функцию launchCollapse,
    которая в свою очередь перебирает все
    элементы с классом collapse и запускает для каждого функцию collapseSAT.
*/

export const launchCollapse = () => {
    document.querySelectorAll('.sat-collapse').forEach(collapseSAT);
};