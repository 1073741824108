// Накладываем хэндлеры на события keyup инпутов для фильтрации таблицы
const filterOnFly = () => {

    // Находим инпуты с соот-им атрибутом
    let inputs = document.querySelectorAll('input[data-filterOnFly]');

    // Вешаем хендлера на соот-ие инпуты
    inputs.forEach(input => {
        input.addEventListener('keyup', keyUpHandler);
    });

}

// Храним ссылку на таблицу (чтобы не искать её каждый раз)
let table;

// Хендлер
const keyUpHandler = (event) => {

    console.log('adsfasfd'); // debug

    /*
       1.  Прочитать дата-атрибут (data-filterOnFly="blablabla")
       2.  Найти таблиицу по айди, который используется значением
           в дата-атрибуте выше (сохранить её в переменную)
       3.  Отфлитровать строки (таблицы)
               а)  пробежаться по всем строкам
               б)  внутри строки пробежаться по всем ячейкам
               в)  если ни одна ячейка не содержит нужного слова,
                   то скрываем (display: none) для всей строчки
                   ИЛИ делаем display с пустой строкой;
    */

    // Ищем соот-ий input
    const input = event.target;

    // То, что написано в input-е
    const filterText = input.value;

    //
    if (!table) {
        // Читаем дата-атрибут и кладём его в соот-ую переменную
        const tableId = input.getAttribute('data-filterOnFly');

        // Ищем таблицу с соот-им айдишником
        table = document.getElementById(tableId);
    }

    //console.log(table);

    // Пробегаем по строчкам таблицы
    for (let i = 1; i < table.rows.length; i++) {

        // Сохраняем строчку таблицы в соот-ю переменную
        const row = table.rows[i];
        //console.log(row);

        // Признак того, что ячейка с нужным текстом найдена
        let found = false;

        // Пробегаем по ячейкам таблицы
        for (let j = 0; j < row.cells.length && !found; j++) {
            const cell = row.cells[j];

            const text = cell.innerText;

            if (text.includes(searchText)) {
                found = true;
                //break;
            }
        }

        row.style.display = found ? "" : "none";
    }
}

export const launchFilterOnFly = () => {
    window.addEventListener('load', filterOnFly);
};