import { styles } from "../lib/DOM"

export const renderBadge = badge => {
  if(!badge) return ''

  if(typeof badge === 'string') return `<div class="tree-branch__badge tree-badge">${badge}</div>`

  const {text, background, color} = badge

  const elementStyles = styles(
    background && `background:${background}`,
    color && `color:${color}`,
  )

  return `<div class="tree-branch__badge tree-badge" style="${elementStyles}">${text}</div>`
}

export const renderBadges = badges => `<div class="tree-branch__badges">${badges.map(renderBadge).join('')}</div>`