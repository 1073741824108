import { classNames, HTML2DOM } from "../lib/DOM";
import { renderBadges } from "./render-badge";

const createBranchId = (branchNodeDeclaration, parentNode) => {
  return [
    parentNode?.id,
    branchNodeDeclaration.label.trim().replaceAll(" ", "")
  ]
    .filter(Boolean)
    .join("_");
};

const defaultIconPath = new URL(
  '/src/features/treenova/icons/folder-gray.svg',
  import.meta.url
);

export const createBranchNode = (
  node,
  parentNode,
  { depth, onToggleRequest, defaultBranchIcon = defaultIconPath }
) => {
  const { icon = defaultBranchIcon, label, badges = [] } = node;

  const cn = classNames("tree-branch", depth === 0 && "tree-branch_top-level");

  const element = HTML2DOM(`
    <div class="${cn}" style="--depth:${depth}">
      <div class="tree-branch__hitarea">
        ${
          icon
            ? `<img class="tree-branch__icon" src="${icon}" alt="${label}">`
            : ""
        }
        <div class="tree-branch__label">${label}</div>
        ${renderBadges(badges)}
      </div>
    </div>
  `);

  const childrenContainer = element.appendChild(
    HTML2DOM('<div class="tree-branch__nodes"></div>')
  );

  const setOpened = (state) => {
    element.classList.toggle("tree-branch_expanded", state);
  };

  const setHasActive = (state) => {
    element.classList.toggle("tree-branch_has-active", state);
  };

  element.querySelector(".tree-branch__hitarea").onclick = onToggleRequest;

  return {
    type: "branch",
    DOM: {
      element,
      childrenContainer
    },
    setOpened,
    setHasActive,
    parent: parentNode,
    id: createBranchId(node, parentNode)
  };
};
