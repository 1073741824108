import { deepForEach } from "./tree-structure.js";

class TreeConfigValidationError extends Error {}

export const validateConfig = ({ defaultBranchIcon, nodes, id }) => {
  if (!id) {
    console.warn("Tree has no id, so its state would not be persisted");
  }

  try {
   

    if (!nodes) throw new TreeConfigValidationError("Nodes array is required");

    deepForEach(nodes, (node) => {
      if (!node.label)
        throw new TreeConfigValidationError(
          'Every tree node should have "label attribute"'
        );
      if (!node.children && !node.link)
        throw new TreeConfigValidationError(
          `Node is not a leaf nor a branch. Please check that every node in your tree config has "children" array or "link" property`
        );

        if(node.badges){
          const badges = node.badges
          if(!Array.isArray(badges)) throw new TreeConfigValidationError(`Badges property should be an array`)
          badges.forEach(badge => {
            if(typeof badge === 'object'){
              if(!badge.text?.trim()) throw new TreeConfigValidationError(`Badge should contain "text" property`)
            }
            if(typeof badge === 'string'){
              if(!badge.trim()) throw new TreeConfigValidationError(`String badge can not be empty`)
            }
          })
        }
    });
  } catch (e) {
    throw e;
  }
};
